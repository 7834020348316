<template>
  <div class="wrapper" id="announcement">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    />
    <div class="content" ref="announcement">
      <div class="table-head">
        <div class="left">
          <input
            type="text"
            class="w200 search"
            placeholder="搜索标题名称"
            @keypress.enter="list()"
            v-model="search.keyword"
          />
          <i class="sdnm-icon sdnm-search" @click="list()"></i>
          <select class="w150" v-model="search.type" @change="list()">
            <option value="">全部类型</option>
            <option :value="row.id" v-for="row in typeList">{{
              row.name
            }}</option>
          </select>
          <flatpickr
            :width="200"
            :config="flatpickrConfig"
            name="noPayTime"
            placeholder="请选择操作日期区间"
            :getDateCallback="getDate"
          ></flatpickr>
          <!-- <div class="flex1"></div> -->
        </div>
        <div class="right">
          <button class="btn primary" @click="create">新建</button>
        </div>
      </div>
      <div class="native-table">
        <table class="table" width="100%" :style="{ 'min-width': '800px' }">
          <thead>
            <tr>
              <th>标题</th>
              <th>创建时间</th>
              <th>创建人</th>
              <th>发送客户</th>
              <th>类型</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody :style="{ height: protbHeight + 'px' }">
            <tr v-for="(row, index) in dataList">
              <td :title="row.title">
                <div class="table-span">{{ row.title4s }}</div>
              </td>
              <td>{{ row.created_at }}</td>
              <td>{{ row.created_by_name }}</td>
              <td>{{ row.customer_num }}</td>
              <td>{{ row.type_name }}</td>
              <td>{{ row.status_name }}</td>
              <td class="operation">
                <a @click="edit(row.id)" v-if="row.status === 0">编辑</a>
                <a @click="detail(row.id)" v-if="row.status === 1">查看详情</a>
                <a @click="del(row.id)">删除</a>
                <a @click="top(row.id, 1)" v-if="index > 0 && row.is_top === 0"
                  >置顶</a
                >
                <a @click="top(row.id, 0)" v-if="row.is_top === 1">取消置顶</a>
              </td>
            </tr>
            <tr v-if="dataList.length === 0">
              <td class="empty" colspan="9">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="search.start"
        :total="config.total"
        :page-length="search.length"
        :pageCallback="list"
      ></pagination>
    </div>
    <mimesis
      :show="ensureShow"
      :closeCallBack="ensureCancel"
      :saveCallBack="ensureDone"
      :btnLoadDisabled="endisabled"
      :showIcon="true"
      :isDangerous="true"
      saveBtn="确认"
    >
      <div>确认删除该公告吗？</div>
    </mimesis>
  </div>
</template>
<script>
/**
 * @module app/announcement/announcement
 * @desc 传蔬农贸版-公告管理
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-06-22
 * @copyright 2021
 */
// import ctheader from '@@/ctheader';
import { tableResize, apiPost } from "../../modules/utils";
// import pagination from '@@/pagination';
// import mimesis from '@@/mimesis';
// import flatpickr from '@@/flatpickr';
// import radio from '@@/radio';
import APIs from "../../modules/APIs";
// import layers from '@@/layers';
import { mapActions } from "vuex";
import * as URL from "../../modules/URLs";
import pagination from "../../components/pagination";
import ctheader from "../../components/ctheader";
import radio from "../../components/radio";
import mimesis from "../../components/mimesis";
import layers from "../../components/layers";
import flatpickr from "../../components/flatpickr";

export default {
  name: "announcement",
  components: { ctheader, pagination, layers, flatpickr, radio, mimesis },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;
      vm.list();
      vm.tResize();
      window.addEventListener("resize", vm.tResize, false);
    });
  },
  created() {},
  beforeDestroy() {},
  data() {
    return {
      pageInfo: {
        titles: [],
        backBtn: false,
      },
      search: {
        start: 0,
        length: 10,
        keyword: "",
        type: "",
        startDate: "",
        endDate: "",
      },
      config: {
        total: 0,
        isCreate: true,
      },
      flatpickrConfig: {
        minDate: null,
        maxDate: null,
      }, // 搜索的时间组件的配置
      protbHeight: 0, // 表格高度(px)
      dataList: [], // 表格数据
      typeList: [
        { id: 1, name: "普通消息" },
        { id: 2, name: "公告消息" },
      ],
      ensureShow: false,
      endisabled: false,
      delId: "",
    };
  },
  methods: {
    ...mapActions(["setAlert"]),
    ensureCancel() {
      this.ensureShow = false;
    },
    ensureDone() {
      let $this = this;
      apiPost({
        url: APIs.ANNOUNCEMENT,
        data: {
          action: "delete",
          id: this.delId,
        },
        success(res) {
          if (res.code === "0") {
            $this.list();
            $this.ensureCancel();
          }
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    del(id) {
      this.delId = id;
      this.ensureShow = true;
    },
    create() {
      this.$router.push(URL.ANNOUNCEMENT_CREATE);
    },
    getDate(dates) {
      this.search.startDate = dates.start;
      this.search.endDate = dates.end;
      this.list();
    },
    detail(id) {
      this.$router.push(`${URL.ANNOUNCEMENT_DETAIL}/${id}`);
    },
    edit(id) {
      this.$router.push(`${URL.ANNOUNCEMENT_EDIT}/${id}`);
    },
    top(id, type) {
      let $this = this;
      apiPost({
        url: APIs.ANNOUNCEMENT,
        data: {
          action: "top.set",
          id,
          is_top: type,
        },
        success(res) {
          if (res.code === "0") {
            $this.list();
          }
          $this.setAlert({ msg: res.msg, type: res.code === "0" });
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 列表获取数据
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-06-22
     */
    list(index = 0) {
      let $this = this;
      this.search.start = index;
      apiPost({
        url: APIs.ANNOUNCEMENT,
        data: {
          action: "lists",
          type: this.search.type,
          start_date: this.search.startDate,
          end_date: this.search.endDate,
          start: this.search.start,
          length: this.search.length,
          title: this.search.keyword,
        },
        success(res) {
          if (res.code === "0") {
            $this.dataList = res.data.map((el) => {
              el.title4s = el.title;
              if (el.title && el.title.length > 15) {
                el.title4s = el.title.splice(0, 15);
              }

              return el;
            });
            $this.config.total = res.total;
          }
          $this.tResize();
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    /**
     * 商品表格的自适应
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-29
     */
    tResize() {
      this.protbHeight = tableResize(
        this.$refs.announcement.clientHeight,
        250,
        this.dataList.length
      );
    },
  },
};
</script>
<style lang="less">
@import url("../../less/common.less");
#announcement {
  .layer {
    min-width: 480px;
  }
  .form {
    padding-top: 15px;
  }
  .announcement-switch {
    float: right;
    margin: 15px;
    margin-right: 35px;
    .radio-box {
      cursor: pointer;
      width: 65px;
      background-color: #257873;
      > input[type="checkbox"]:checked + label {
        left: 48px;
      }
      > input[type="checkbox"]:checked ~ .radio-mask {
        left: 48px;
        width: 18px;
        background-color: #257873;
      }
      > .radio-mask {
        width: 67px;
        background-color: #aeb5bf;
      }
      .radio-tip {
        font-size: 12px;
        color: #fff;
        position: absolute;
        right: 15px;
        &.right {
          right: 25px;
        }
      }
    }
  }
  em {
    font-style: normal;
  }
  .side-content {
    .fc(12px, #333);
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .tit {
    .fc(20px, #253d44);
  }
  .bl-content {
    .pdt(10px);
    .pdb(10px);
    .lh(30px);
    .brd-b(1px, solid, #eee);
    .txt-l {
      .fc(14px, #666);
    }
  }
  .flex1 {
    flex: 1;
  }
  .label {
    .pd(3px, 5px, 3px, 5px);
    .fc(12px, #fff);
    .bgc(#36a852);
    .brdr(3px);
    .mgl(10px);
  }
  .label.disabled {
    .bgc(#e83f32);
  }
  .label-name {
    color: #a1a1a1;
  }
  .table-head {
    display: flex;
    align-items: center;
    input {
      .mgr(10px);
    }
    input:last-child {
      .mgr(0);
    }
    .sdnm-search {
      // .mgl(10px);
    }
    select {
      vertical-align: top;
    }
  }
  .form {
    padding-left: 20px;
    label {
      width: 120px;
    }
  }
  .field {
    .tar {
      text-align: right;
    }
    input {
      padding-right: 30px;
    }
    .unit {
      color: #a0b9dc;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      margin-left: -28px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .m-mimesis {
    z-index: 1002;
  }
}
</style>
